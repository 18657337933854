import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Grid, IconButton, Button } from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useFirebase } from 'react-redux-firebase';

function TitleBar({ drawerWidth }) {
  const firebase = useFirebase();
  const dispatch = useDispatch();
  const open = useSelector((state) => state.navOpen);
  const allPurchases = useSelector((state) => state.allPurchases);
  const thisPurchase = useSelector((state) => state.thisPurchase);
  const auth = useSelector((state) => {
    return state.firebase.auth;
  });

  const classes = makeStyles((theme) => ({
    appBar: {
      transition: theme.transitions.create([ 'margin', 'width' ], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create([ 'margin', 'width' ], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: 'none',
    },
    title: {
      flexGrow: 1,
    },
  }))();

  const engName = window.location.pathname.split('/')[1];
  useEffect(
    () => {
      if (engName === 'data') {
      } else {
        if (!allPurchases.length) return;
        const name = allPurchases.find((item) => item.engName === engName);
        if (name) {
          dispatch({
            type: 'NAVCLOSE',
          });
          dispatch({
            type: 'SETTHISPURCHASE',
            value: name,
          });
        } else {
          dispatch({
            type: 'SETTHISPURCHASE',
            value: {},
          });
        }
      }
    },
    [ engName, allPurchases, dispatch ]
  );

  if (engName === '') {
    document.title = 'LookUp : Frozen Inside Lite';
  } else {
    document.title = thisPurchase.name ? `${thisPurchase.name} 정보 조회 : Frozen Inside Lite` : document.title;
  }
  return (
    <Grid item xs={12}>
      <AppBar position="static" className={`${classes.appBar} ${open && classes.appBarShift}`}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => dispatch({ type: 'NAVOPEN' })}
            edge="start"
            className={`${classes.menuButton} ${open && classes.hide}`}
          >
            <MenuIcon />
          </IconButton>
          <Typography className={classes.title}>
            <Link to="/" style={{ color: 'inherit', textDecoration: 'none' }}>
              {engName === '' ? (
                <strong>Frozen Inside Lookup</strong>
              ) : (
                <React.Fragment>
                  <strong>{thisPurchase.name}</strong> 정보
                </React.Fragment>
              )}
            </Link>
          </Typography>
          {auth.phoneNumber ? (
            <Button
              color="inherit"
              onClick={(e) => {
                e.preventDefault();
                firebase.auth().signOut();
              }}
            >
              {auth.phoneNumber.substr(auth.phoneNumber.length - 4)}
            </Button>
          ) : (
            <Button
              color="inherit"
              onClick={(e) => {
                e.preventDefault();
                dispatch({
                  type: 'PHOOPEN',
                });
              }}
            >
              번호인증
            </Button>
          )}
        </Toolbar>
      </AppBar>
    </Grid>
  );
}

export default TitleBar;
