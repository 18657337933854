import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { Menu as MenuIcon, ArrowDropDown as ArrowDropDownIcon } from '@material-ui/icons';

function GoogleSheetExample({ offset = 1, name, phonenumber, sheetname, annSelected = false, annSheetname }) {
  name = name || '성함';
  phonenumber = phonenumber || '전화번호';
  sheetname = sheetname || '공구리스트';
  annSheetname = annSheetname || '공지사항';

  const classes = makeStyles((theme) => ({
    exampletable: {
      '&': {
        textAlign: 'center',
        borderCollapse: 'collapse',
        border: '1.5px solid #CECECE',
      },

      '& td': {
        border: '1px solid #F8F8F8',
        paddingLeft: '1.5em',
        paddingRight: '1.5em',
      },
    },
    columnrow: {
      backgroundColor: '#F8F9FA',
      color: '#85888C',
      '& td': {
        border: '1.5px solid #CECECE!important',
      },
    },

    rowempty: {
      borderRight: '3px solid rgb(188, 188, 188)!important',
      borderBottom: '3px solid rgb(188, 188, 188)!important',
    },

    rownumber: {
      paddingLeft: '1em!important',
      paddingRight: '1em!important',
      backgroundColor: '#F8F9FA',
      border: '1.5px solid #CECECE!important',
      color: '#85888C',
    },

    sheetham: {
      backgroundColor: '#F1F3F4!important',
      textAlign: 'right!important',
    },
    sheetname: {
      '&': {
        backgroundColor: '#F1F3F4!important',
        color: '#188038!important',
        textAlign: 'left!important',
      },
      '& div': {
        margin: '5px',
        display: annSelected ? 'flex' : 'inline-block',
        overflowX: 'auto',
      },
      '& div .MuiGrid-container': {
        // backgroundColor: '#FFF!important',
        padding: '0.1em 0.5em',
        display: 'flex',
        alignItems: 'center',
      },
      '& div .MuiGrid-container:last-child': {
        backgroundColor: '#FFF!important',
      },
      '& div *': {
        margin: 0,
        padding: 0,
      },
    },
  }))();
  var testData = !annSelected
    ? [
        [ name, phonenumber, '주소' ],
        [ '김 프붕23', '010-1234-1234', '한국' ],
        [ '안나', '01006210621', '아렌델' ],
        [ '엘사', '1222', '노덜드라' ],
      ]
    : [ [ '입금날짜', '2020년 02월 10일 00시까지', [] ], [ '통장번호', '프갤은행 104-2563-2345 (김프붕)', [] ], [ '탑승객', '150명', [] ] ];
  for (var i = 0; i < offset - 1; i++) {
    testData.unshift([ i, '', '' ]);
  }
  return (
    <table className={classes.exampletable}>
      <tbody>
        <tr className={classes.columnrow}>
          <td className={classes.rowempty} />
          <td>A</td>
          <td>B</td>
          <td>C</td>
        </tr>
        {testData.map((value, index) => (
          <tr key={value[0]}>
            <td className={classes.rownumber}>{index + 1}</td>
            <td>{isNaN(value[0]) ? value[0] : ''}</td>
            <td>{value[1]}</td>
            <td>{value[2]}</td>
          </tr>
        ))}
        <tr>
          <td className={classes.rownumber}>...</td>
          <td>...</td>
          <td>...</td>
          <td>...</td>
        </tr>
        <tr>
          <td colSpan="2" className={classes.sheetham}>
            <MenuIcon />
          </td>
          <td colSpan="2" className={classes.sheetname}>
            <div>
              <Grid container>
                <Grid item>{sheetname}</Grid>
                <Grid item>
                  <ArrowDropDownIcon />
                </Grid>
              </Grid>
              {annSelected && (
                <Grid container>
                  <Grid item>{annSheetname}</Grid>
                  <Grid item>
                    <ArrowDropDownIcon />
                  </Grid>
                </Grid>
              )}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default GoogleSheetExample;
