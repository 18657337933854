const defaultData = {
  name: '',
  engName: '',
  _id: 0,
};

const thisPurchaseReducer = (state = defaultData, action) => {
  switch (action.type) {
    case 'SETTHISPURCHASE':
      return action.value;
    case 'DEFAULTTHISPURCHASE':
      return defaultData;
    default:
      return state;
  }
};

export default thisPurchaseReducer;
