const socketIoIdReducer = (state = '', action) => {
  switch (action.type) {
    case 'setSocketIo':
      return action.value;
    default:
      return state;
  }
};

export default socketIoIdReducer;
