import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Grid,
  TextField,
  Typography,
  Paper,
  makeStyles,
  InputAdornment,
  FormHelperText,
  Divider,
  FormControlLabel,
  Switch,
  Button,
  CircularProgress,
  Tooltip,
  Select,
  MenuItem,
  InputLabel,
} from '@material-ui/core';
import {
  Check as CheckIcon,
  ErrorOutline as ErrorOutlineIcon,
  Settings as SettingsIcon,
  AddCircle as AddCircleIcon,
} from '@material-ui/icons';
import GoogleSheetExample from './GoogleSheetExample';
import { green } from '@material-ui/core/colors';
import axios from 'axios';
import uriList from '../../data/uriList';
import ConnectionWarning from '../utils/ConnectionWarning';
import { Alert } from '@material-ui/lab';
import Complete from './Complete';
import queryString from 'query-string';

const useStyles = makeStyles((theme) => ({
  root: {
    '&': {
      padding: theme.spacing(5),
    },
  },
  form: {
    maxWidth: '1200px',
    margin: 'auto',
  },
  grid: {
    '&': {
      justifyContent: 'flex-start',
    },
    '&:not(:last-child)': {
      marginBottom: '2em',
    },
    '&:not(:first-child)': {
      marginTop: '1em',
    },
  },

  wrapper: {
    // margin: theme.spacing(1),
    position: 'relative',
  },

  buttonProcess: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },

  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },

  addBot: {
    padding: theme.spacing(2),

    '& ol': {
      marginTop: theme.spacing(1),
      marginBottom: 0,
    },
  },
}));

const koreanerrormsg = {
  403: '봇이 읽기 권한이 없습니다. 봇을 스프레드 시트에 추가해주세요.',
  400: '시트 설정에 문제가 있습니다.',
  400.1: '구글시트 파일이 아닙니다. 엑셀 파일일경우 구글시트로 변환후 시도해주세요.',
  400.2: '시트 이름설정이 잘못되었습니다. 다시 확인해주세요.\n파일이름이 아니고 시트 탭 이름입니다.',
  4: '행 제목에 문제가 있거나 제목행 위치에 문제가 있습니다.',
  NAME_EXIST: '영문이름이 중복됩니다.',
};

function EditPurchase(props) {
  const dispatch = useDispatch();
  var data = useSelector((state) => state.purchaseData);
  // var lastAgent = useSelector((state) => state.lastAgentId);
  var queue = useSelector((state) => state.purchaseQueue);
  const socketId = useSelector((state) => state.socketIoId);

  const [ code, setCode ] = useState({});
  const [ googleSheetUrl, setGoogleSheetUrl ] = useState({
    ok: false,
    url: '',
    id: '',
  });
  const [ purchaseName, setPurchaseName ] = useState('');
  const [ purchaseEngName, setPurchaseEngName ] = useState('');
  const [ emailaddress, setEmailAddress ] = useState('');
  const [ headingName, setHeadingName ] = useState('');
  const [ headingPhone, setHeadingPhone ] = useState('');
  const [ dataSheetName, setDataSheetName ] = useState('');
  const [ offset, setOffset ] = useState(1);
  const [ announcementsheet, setAnnouncementSheet ] = useState({
    sheetname: undefined,
    enabled: false,
  });
  const [ privacy, setPrivacy ] = useState(0);

  const [ confirmingSetting, setConfirmingSetting ] = useState(false);
  const [ warnOpen, setWarnOpen ] = useState(false);
  const [ dataerror, setDataerror ] = useState(undefined);

  const [ finalEmail, setFinalEmail ] = useState('');

  const removeQueue = useCallback(
    () => {
      axios.post(`${uriList.API_URI}/purchase/removequeue`, {
        socketId: socketId,
      });
    },
    [ socketId ]
  );
  const sendData = useCallback(
    (e, real) => {
      e.preventDefault();

      const required = [ purchaseName, purchaseEngName, emailaddress, googleSheetUrl.id, googleSheetUrl.ok ];
      for (var i = 0; i < required.length; i++) {
        if (!required[i]) return console.log(`${i} = ${required[i]}    is not valid`);
      }

      setConfirmingSetting(true);
      var postData = {
        engName: purchaseEngName,
        name: purchaseName,
        email: emailaddress,
        googleSheetId: googleSheetUrl.id,
        sheetname: dataSheetName || '공구리스트',
        sheetoffset: offset,
        announcementsheetname: announcementsheet.enabled ? announcementsheet.sheetname || '공지사항' : undefined,
        headingName: headingName || '성함',
        headingPhone: headingPhone || '전화번호',
        socketioId: socketId,
        privacy: privacy,
        code: code,
      };

      if (!real) {
        // Test

        if (code) {
          postData = {
            ...postData,
            engName: `${purchaseEngName}${Math.floor(Math.random() * 1000)}`,
          };
          // console.log(postData);
        }

        axios
          .post(`${uriList.API_URI}/purchase/test`, postData)
          .then(({ data }) => {
            dispatch({
              type: 'setLastAgentId',
              value: data.workerId,
            });
            setDataerror(undefined);
          })
          .catch((e) => {
            setConfirmingSetting(false);
            if (e.status === 404) {
              setWarnOpen(true);
            } else {
              console.log(e.response.data);
              setDataerror(e.response.data);
            }
          });
      } else {
        if (!code) {
          axios
            .post(`${uriList.API_URI}/purchase/add`, postData)
            .then(({ data }) => {
              // props.history.push('/success');
              setFinalEmail(data.email);
            })
            .catch((e) => {
              setConfirmingSetting(false);
              if (e.status === 404) {
                setWarnOpen(true);
              } else {
                console.log(e.response.data);
                setDataerror(e.response.data);
              }
            });
        } else {
          postData.code = code;
          axios
            .post(`${uriList.API_URI}/purchase/edit`, postData)
            .then(({ data }) => {
              // props.history.push('/success');
              // setFinalEmail();
              console.log(data);
              window.location.reload();
            })
            .catch((e) => {
              console.log(e);
              setConfirmingSetting(false);
              if (e.status === 404) {
                setWarnOpen(true);
              } else {
                console.log(e.response.data);
                setDataerror(e.response.data);
              }
            });
        }
      }

      // setConfirmingSetting(true);
    },
    [
      socketId,
      purchaseName,
      purchaseEngName,
      emailaddress,
      googleSheetUrl.id,
      dataSheetName,
      offset,
      announcementsheet,
      headingName,
      headingPhone,
      code,
      privacy,
      dispatch,
      googleSheetUrl.ok,
    ]
  );

  useEffect(
    () => {
      data && setConfirmingSetting(false);
      data.error && typeof data.error === 'string' && setDataerror(JSON.parse(data.error));
    },
    [ data ]
  );

  useEffect(
    () => {
      dispatch({
        type: 'SETPURCHASEDATA',
        value: {},
      });

      dispatch({
        type: 'SETPURCHASEQUEUE',
        value: -1,
      });
    },
    [ dispatch ]
  );

  useEffect(
    () => {
      const values = queryString.parse(props.location.search);
      var authCode = values.code;
      setCode(authCode);

      if (authCode) {
        const engName = props.location.pathname.split('/')[1];
        axios
          .post(`${uriList.API_URI}/purchase/edit/lookup`, {
            type: 'get',
            engName: engName,
            code: authCode,
          })
          .then((doc) => {
            const { name, engName, owner, status, googlesheet } = doc.data;
            console.log(doc.data);
            setPurchaseName(name);
            setPurchaseEngName(engName);
            setEmailAddress(owner);
            setHeadingName(googlesheet.heading.name);
            setHeadingPhone(googlesheet.heading.phoneNo);
            setDataSheetName(googlesheet.sheetname);
            setOffset(googlesheet.offset);
            googlesheet.announcementsheetname &&
              setAnnouncementSheet({
                enabled: true,
                sheetname: googlesheet.announcementsheetname,
              });
            setPrivacy(status < 0 ? 0 : status);
            setGoogleSheetUrl({
              ok: true,
              url: googlesheet.spreadsheetId,
              id: googlesheet.spreadsheetId,
            });
          })
          .catch((e) => {
            if (e.status === 404) {
              setWarnOpen(true);
            }
          });
      }
    },
    [ props.location ]
  );

  useEffect(
    () => {
      return () => removeQueue();
    },
    [ removeQueue ]
  );

  const classes = useStyles();
  return (
    (!code || purchaseName) && (
      <Paper className={classes.root}>
        {finalEmail && <Complete email={finalEmail} name={purchaseName} />}
        {warnOpen && <ConnectionWarning closecb={() => setWarnOpen(false)} />}
        <form className={classes.form} noValidate autoComplete="off">
          <Typography variant="h5" gutterBottom>
            {code ? `${purchaseName} 설정` : '새로운 항목 추가'}
          </Typography>
          <Grid container spacing={2} className={classes.grid}>
            <Typography component={Grid} item xs={12} variant="h6" gutterBottom>
              기본정보
            </Typography>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                label="이름"
                placeholder="이름을 입력해주세요"
                value={purchaseName}
                onChange={(e) => setPurchaseName(e.target.value)}
                error={!purchaseName}
                helperText={!purchaseName && '필수항목입니다.'}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                label="영문이름"
                placeholder="링크에 이용될 이름을 적어주세요"
                value={purchaseEngName}
                onChange={(e) => {
                  const alphaNumber = e.target.value.trim().replace(/[^0-9a-zA-Z]/g, '');
                  setPurchaseEngName(alphaNumber);
                }}
                error={!purchaseEngName}
                disabled={code !== undefined}
                helperText={!purchaseEngName && '필수항목입니다.'}
                inputProps={{
                  autoCapitalize: 'none',
                }}
                fullWidth
                required
                aria-describedby="purchaseEngName-helpertext"
              />
              <FormHelperText id="purchaseEngName-helpertext">
                {purchaseEngName ? (
                  `https://lookup.frozeninside.com/${purchaseEngName}`
                ) : (
                  '입력이 안되면 한/영키를 확인하세요. (영어숫자만 입력가능)'
                )}
              </FormHelperText>
            </Grid>
            <Grid item xs={12} md>
              <TextField
                label="이메일주소"
                placeholder="받을 수 있는 이메일을 입력해주세요"
                value={emailaddress}
                onChange={(e) => {
                  setEmailAddress(e.target.value.trim());
                }}
                error={!emailaddress}
                helperText={!emailaddress && '필수항목입니다.'}
                inputProps={{
                  autoCapitalize: 'none',
                }}
                fullWidth
                required
                aria-describedby="emailaddress-helpertext"
              />
              <FormHelperText id="emailaddress-helpertext">절대로 이메일을 공개하지 않습니다.</FormHelperText>
            </Grid>
            <Grid item xs={12} md={2}>
              <InputLabel shrink id="purchase-privacy-select-label">
                공개설정
              </InputLabel>
              <Select
                labelId="purchase-privacy-select-label"
                id="purchase-privacy-select"
                value={privacy}
                disabled={!code}
                onChange={(e) => setPrivacy(e.target.value)}
                fullWidth
                aria-describedby="purchase-privacy-helpertext"
              >
                <MenuItem value={0}>비공개</MenuItem>
                <MenuItem value={2}>미등록</MenuItem>
                <MenuItem value={1}>공개</MenuItem>
              </Select>
              <FormHelperText id="purchase-privacy-helpertext">{!code && '이메일 인증 후 변경이 가능합니다.'}</FormHelperText>
            </Grid>
          </Grid>
          <Divider />
          <Grid container spacing={2} className={classes.grid}>
            <Typography component={Grid} item xs={12} variant="h6" gutterBottom>
              구글 시트 정보
            </Typography>

            <Grid item container xs={12} spacing={2} justify="center">
              <Grid item container justify="center" sm={12} md>
                <GoogleSheetExample
                  offset={offset}
                  name={headingName}
                  phonenumber={headingPhone}
                  sheetname={dataSheetName}
                  annSelected={announcementsheet.enabled}
                  annSheetname={announcementsheet.sheetname}
                />
              </Grid>
              <Grid item sm={12} md>
                <Paper className={classes.addBot}>
                  <Typography variant="subtitle1" gutterBottom>
                    봇 추가하는 방법{' '}
                    <a
                      href="https://docs.google.com/document/d/1I7Ozfzl3cHNMoGc2E1L44LHJSusQ978trHCbsLAkyg4"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      (공구 추가 메뉴얼)
                    </a>
                  </Typography>
                  <TextField
                    id="bot-email-readonly"
                    label="봇 이메일"
                    defaultValue="frozen-public-purchase@dc-frozen-1577723359010.iam.gserviceaccount.com"
                    InputProps={{
                      readOnly: true,
                    }}
                    onClick={(e) => {
                      e.target.setSelectionRange(0, e.target.value.length);
                    }}
                    fullWidth
                  />
                  <ol>
                    <li>구글 스프레드 시트를 연다</li>
                    <li>'공유'를 누른다</li>
                    <li>공유할 사용자에 봇 이메일을 추가한다</li>
                  </ol>
                </Paper>
              </Grid>
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                label="스프레드시트 ID"
                placeholder="URL을 복사해주세요"
                value={googleSheetUrl.url}
                onChange={(e) => {
                  var url = e.target.value;
                  var ok = false;

                  //https://docs.google.com/spreadsheets/d/12InftkCgsfa_uKPS_Y0LzsMWvVh7tIchcSGonW2L_h8/edit#gid=0

                  var finalId = '';
                  if (url.includes('/')) {
                    var id = url.split('spreadsheets/d/');
                    finalId = '';
                    if (id.length === 2) {
                      id = id[1].split('/');
                      if (id.length >= 1) {
                        finalId = id[0];
                        ok = true;
                      }
                    }
                  } else {
                    finalId = url;
                    ok = true;
                  }

                  setGoogleSheetUrl({
                    ok: ok,
                    url: url,
                    id: finalId,
                  });
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {googleSheetUrl.ok ? <CheckIcon htmlColor="#007723" /> : <ErrorOutlineIcon htmlColor="#db2727" />}
                    </InputAdornment>
                  ),
                }}
                error={!(googleSheetUrl.ok && googleSheetUrl.url)}
                helperText={
                  googleSheetUrl.id ? (
                    `ID: ${googleSheetUrl.id}`
                  ) : !googleSheetUrl.url ? (
                    `구글시트 주소를 붙여넣기 해주세요`
                  ) : !googleSheetUrl.ok ? (
                    `링크를 인식할 수 없습니다.`
                  ) : (
                    ''
                  )
                }
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {dataerror && dataerror.errorcode === 400.2 ? (
                <React.Fragment>
                  <InputLabel shrink id="purchase-sheetname-select-label">
                    시트이름
                  </InputLabel>
                  <Select
                    labelId="purchase-sheetname-select-label"
                    id="purchase-sheetname-select"
                    value={dataerror.details.sheets.includes(dataSheetName) ? dataSheetName : ''}
                    onChange={(e) => setDataSheetName(e.target.value)}
                    fullWidth
                    // aria-describedby="purchase-sheetname-helpertext"
                  >
                    <MenuItem value="">선택해주세요</MenuItem>
                    {dataerror.details.sheets.map((tabItem) => (
                      <MenuItem key={tabItem} value={tabItem}>
                        {tabItem}
                      </MenuItem>
                    ))}
                  </Select>
                </React.Fragment>
              ) : (
                <TextField
                  label="시트이름"
                  placeholder="공구리스트"
                  value={dataSheetName}
                  onChange={(e) => setDataSheetName(e.target.value)}
                  fullWidth
                />
              )}
            </Grid>
            <Grid item xs={6} md>
              <TextField
                label="성함 행"
                placeholder="성함"
                value={headingName}
                onChange={(e) => setHeadingName(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={6} md>
              <TextField
                label="전화번호 행"
                placeholder="전화번호"
                value={headingPhone}
                onChange={(e) => setHeadingPhone(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={6} md={2}>
              <TextField
                label="제목행 위치"
                value={offset}
                onChange={(e) => {
                  if (!isNaN(e.target.value)) setOffset(e.target.value);
                }}
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid container item xs={12} justify="flex-start">
              <Grid item xs={6} sm={4} md={3}>
                <FormControlLabel
                  // value={announcementsheet.enabled}
                  control={
                    <Switch
                      color="primary"
                      checked={announcementsheet.enabled}
                      onChange={(e) =>
                        setAnnouncementSheet((prev) => ({
                          ...prev,
                          enabled: !prev.enabled,
                        }))}
                    />
                  }
                  label="공지사항 시트"
                  labelPlacement="end"
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <TextField
                  variant="outlined"
                  label="공지사항 시트이름"
                  placeholder="공지사항"
                  value={announcementsheet.sheetname || ''}
                  disabled={!announcementsheet.enabled}
                  onChange={(e) => {
                    e.persist();
                    setAnnouncementSheet((prev) => ({
                      ...prev,
                      sheetname: e.target.value,
                    }));
                  }}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className={classes.grid} />
          </Grid>
          <Grid container spacing={1} justify="center">
            <Grid item xs={12}>
              {dataerror &&
              dataerror.errorcode !== 1 && (
                <Alert severity="error">
                  <pre>{JSON.stringify(dataerror, null, 2)}</pre>
                  <strong>{koreanerrormsg[dataerror.errorcode]}</strong>
                </Alert>
              )}
            </Grid>
            <Grid item>
              <div className={classes.wrapper}>
                <Tooltip title="설정을 확인합니다.">
                  <Button
                    color="default"
                    variant="contained"
                    startIcon={<SettingsIcon />}
                    disabled={confirmingSetting}
                    onClick={(e) => sendData(e, false)}
                  >
                    설정확인
                  </Button>
                </Tooltip>
                {confirmingSetting && <CircularProgress size={24} className={classes.buttonProcess} />}
              </div>
            </Grid>
            <Grid item>
              <div className={classes.wrapper}>
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<AddCircleIcon />}
                  disabled={confirmingSetting || !(dataerror && dataerror.errorcode === 1)}
                  onClick={(e) => sendData(e, true)}
                >
                  {code ? '수정' : '추가'}{' '}
                </Button>
                {confirmingSetting && <CircularProgress size={24} className={classes.buttonProcess} />}
              </div>
            </Grid>
            <Grid item>
              <Button disabled>대기: {queue}</Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    )
  );
}

export default EditPurchase;
