import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import socket from 'socket.io-client';
import uriList from '../data/uriList';
import ConnectionWarning from './utils/ConnectionWarning';

const io = socket.connect(`${uriList.SOCKET_URI}`);

function SocketPractice() {
  // const [io, setIo] = useState(undefined)
  const dispatch = useDispatch();
  const [ disconnected, setDisconnected ] = useState(false);

  useEffect(
    () => {
      io.on('message', (message) => {
        if (message.id) {
          dispatch({
            type: 'setSocketIo',
            value: message.id,
          });
          setDisconnected(false);
        }
      });

      io.on('purchaseData', (message) => {
        // console.log({ data: '*****' });
        dispatch({
          type: 'SETPURCHASEDATA',
          value: message,
        });
      });

      io.on('purchaseQueue', (message) => {
        if (typeof message.queue == 'number') {
          // console.log({ queue: message.queue });
          dispatch({
            type: 'SETPURCHASEQUEUE',
            value: message.queue,
          });
        }
      });

      io.on('disconnect', () => {
        setDisconnected(true);
        dispatch({
          type: 'setSocketIo',
          value: '',
        });
        // alert('서버와 연결이 끊어졌습니다.');
        // window.location.reload();
      });

      return () => {
        io.removeAllListeners();
      };
    },
    [ dispatch ]
  );
  return (
    <React.Fragment>
      {disconnected && <ConnectionWarning closecb={() => setDisconnected(false)} forceRefresh={true} />}
    </React.Fragment>
  );
}

export default SocketPractice;
