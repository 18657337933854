import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { createStore, combineReducers } from 'redux';
import allReducer from './components/reducers/index';

import { ThemeProvider, createMuiTheme } from '@material-ui/core';

import { Provider } from 'react-redux';

// Firebase Setup Imports
import firebase from 'firebase/app';
import { ReactReduxFirebaseProvider, firebaseReducer } from 'react-redux-firebase';
import 'firebase/auth';
import fbConfig from './data/fbConfig';

import SocketPractice from './components/SocketPractice';

// Firebase Setup
firebase.initializeApp(fbConfig);

const store = createStore(
  combineReducers({
    ...allReducer,
    firebase: firebaseReducer,
  }),
  process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

const reactReduxFirebaseConfig = {
  // userProfile: 'users',
  // useFirestoreForProfile: true,
};

const reactReduxFirebaseProps = {
  firebase,
  config: reactReduxFirebaseConfig,
  dispatch: store.dispatch,
  // createFirestoreInstance,
};

const theme = createMuiTheme({
  //Style
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Nanum Gothic"',
      'Gulim',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

ReactDOM.render(
  <React.Fragment>
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...reactReduxFirebaseProps}>
        <SocketPractice />
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </ReactReduxFirebaseProvider>
    </Provider>
  </React.Fragment>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
