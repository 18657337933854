const navOpenReducer = (state = false, action) => {
  switch (action.type) {
    case 'NAVOPEN':
      return true;
    case 'NAVCLOSE':
      return false;
    case 'NAVSET':
      return action.value;
    default:
      return state;
  }
};

export default navOpenReducer;
