import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  List,
  Divider,
  IconButton,
  ListItem,
  ListItemText,
  ListSubheader,
  ListItemSecondaryAction,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

function MenuMyPurchase() {
  const dispatch = useDispatch();
  const allPurchases = useSelector((state) => state.allPurchases);
  var myPurchases = useSelector((state) => state.myPurchases);
  var [ myList, setMyList ] = useState([]);

  useEffect(
    () => {
      const list = myPurchases.reduce((prev, value) => {
        const data = allPurchases.find((element) => element.engName === value);
        data && prev.push(data);
        return prev;
      }, []);
      setMyList(list);
    },
    [ myPurchases, allPurchases ]
  );

  return myList.length ? (
    <React.Fragment>
      <List
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            나의 공구 목록
          </ListSubheader>
        }
      >
        {myList.map((purchase) => (
          <ListItem
            key={`my-${purchase._id}`}
            button
            component={Link}
            to={{ pathname: `/${purchase.engName}`, query: { lookup: true } }}
            onClick={() => dispatch({ type: 'NAVCLOSE' })}
            // style={{ color: 'inherit', textDecoration: 'none' }}
            selected={window.location.pathname.split('/')[1] === purchase.engName}
          >
            <ListItemText primary={purchase.name} />
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                onClick={() => {
                  dispatch({
                    type: 'DEL_MYPURCHASE',
                    value: purchase.engName,
                  });
                }}
              >
                <CloseIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
      <Divider />
    </React.Fragment>
  ) : null;
}

export default MenuMyPurchase;
