import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  Grid,
  Typography,
  makeStyles,
  Divider,
} from '@material-ui/core';

import axios from 'axios';
import uriList from '../data/uriList';

import PleaseWait from './PleaseWait';

function ViewData(props) {
  const dispatch = useDispatch();
  var data = useSelector((state) => state.purchaseData);
  var lastAgent = useSelector((state) => state.lastAgentId);
  var queue = useSelector((state) => state.purchaseQueue);
  const socketId = useSelector((state) => state.socketIoId);
  const thisPurchase = useSelector((state) => state.thisPurchase);

  // data = {
  //   workerId: '5e2ebe1c0e62c113460f3fca',
  //   data: {
  //     번호: '1',
  //     TIMESTAMP: '2020-01-02',
  //     성함: '김프붕32',
  //     전화번호: '010-1234-1234',
  //     주소: '아렌델 특별시 아렌로 311길 아렌델호텔 2204호',
  //     '사는 갯수': '2',
  //     우편번호: '10495',
  //     환급계좌: '195-239-234795',
  //     환급은행: '프갤은행',
  //     1: 1,
  //     2: 2,
  //     3: 4,
  //     5: 6,
  //     7: 8,
  //     15: 23,
  //     135: 43,
  //     32: 12,
  //   },
  // };

  // lastAgent = '5e2ebe1c0e62c113460f3fca';

  useEffect(
    () => {
      return () => {
        axios.post(`${uriList.API_URI}/purchase/removequeue`, {
          socketId: socketId,
        });
      };
    },
    [ socketId ]
  );

  useEffect(
    () => {
      if (data.data) {
        dispatch({
          type: 'ADD_MYPURCHASE',
          value: thisPurchase.engName,
        });
      }
    },
    [ data.data, thisPurchase.engName, dispatch ]
  );

  console.log({
    data: {
      ...data,
      error: data.error,
      data: data.data && '**data**',
    },
    lastAgent: lastAgent,
    queue: queue,
  });

  // For Error Tracking
  var error = data.error;
  try {
    error = error && JSON.parse(error);
  } catch (e) {}
  if (typeof error == 'string') {
    error = {
      message: error,
      errorcode: '00',
    };
  }

  const classes = makeStyles((theme) => ({
    list: {
      backgroundColor: theme.palette.background.paper,
      'max-width': '80vh',
    },
    listSection: {
      backgroundColor: 'inherit',
    },
    ul: {
      backgroundColor: 'inherit',
      padding: 0,
    },
    pubpurinfo: {
      'padding-top': theme.spacing(3),
      'padding-bottom': theme.spacing(3),
    },
    '& .displayData': {
      padding: theme.spacing(2),
      minWidth: 200,
    },
  }))();

  return (
    <React.Fragment>
      {Object.keys(data).length === 0 || data.workerId !== lastAgent ? (
        <PleaseWait {...props} />
      ) : data.error ? (
        <Dialog open={true} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{`오류가 발생했습니다 - #${error.errorcode}`}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{error.message}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => props.history.goBack()} color="primary" autoFocus>
              뒤로가기
            </Button>
          </DialogActions>
        </Dialog>
      ) : data.data ? (
        <Paper className="DisplayData">
          <Grid container item justify="center" alignItems="center" spacing={1} className={classes.pubpurinfo}>
            <Grid item>
              <Typography variant="h3" gutterBottom>
                {thisPurchase.name}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h5" gutterBottom>
                ({thisPurchase.engName})
              </Typography>
            </Grid>
          </Grid>
          <Grid container justify="center" alignItems="center" spacing={2}>
            <List subheader={<li />} className={classes.list}>
              <li key="MyInformation" className={classes.listSection}>
                {data.announcement && (
                  <ul className={classes.ul}>
                    <ListSubheader>공지사항</ListSubheader>
                    {Object.keys(data.announcement).map((key, index) => {
                      return (
                        <ListItem key={`item-${key}`}>
                          <ListItemText primary={key} secondary={data.announcement[key]} />
                        </ListItem>
                      );
                    })}
                    <Divider />
                  </ul>
                )}
                <ul className={classes.ul}>
                  <ListSubheader>내 정보</ListSubheader>
                  {Object.keys(data.data).map((key, index) => {
                    return (
                      <ListItem key={`item-${key}`}>
                        <ListItemText primary={key} secondary={data.data[key]} />
                      </ListItem>
                    );
                  })}
                </ul>
              </li>
            </List>
          </Grid>
        </Paper>
      ) : (
        <div>View Data</div>
      )}
    </React.Fragment>
  );
}

export default ViewData;
