import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { CssBaseline, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';

// import './App.css';

import uriList from './data/uriList';
import Heading from './components/Heading/';
import HomePage from './components/HomePage';
import ViewData from './components/ViewData';
import EditPurchase from './components/EditPurchase/EditPurchase';
import LookUpForm from './components/LookUpForm';
import PhoneAuth from './components/Auth/PhoneAuth'
// import SiteInfo from './components/SiteInfo';

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  // root: {
  //   display: 'flex',
  // },
  content: {
    // flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
  },
}));

function App() {
  // const socketIoId = useSelector((state) => state.socketIoId);
  const dispatch = useDispatch();
  const open = useSelector((state) => state.navOpen);
  const classes = useStyles();

  useEffect(
    () => {
      axios.get(`${uriList.API_URI}/purchase?sort=createdDate&sort_type=-1`).then((doc) => {
        dispatch({
          type: 'SETPURCHASE',
          value: doc.data,
        });
      });
    }, [dispatch]
  );

  return (
    <div className="App">
      <CssBaseline />
      <Router>
        <Route render={(props) => <Heading {...props} drawerWidth={drawerWidth} classes={classes} />} />
        <div className={`content ${classes.content} ${open ? classes.contentShift : ''}`}>
          <Switch>
            <Route path="/pho" exact component={PhoneAuth} />
            <Route path="/add" exact component={EditPurchase} />
            <Route path="/data" exact component={ViewData} />
            <Route path="/:engName" exact component={LookUpForm} />
            <Route path="/:engName/edit" exact component={EditPurchase} />
            <Route path="/" exact component={HomePage} />
          </Switch>
          <Paper square elevation={0}>
            <div style={{ margin: 'auto 1em' }}>
              © FrozenInside {new Date().getFullYear().toString()} - <a href="mailto:frozen@frozeninside.com">frozen@frozeninside.com</a>
            </div>
          </Paper>
        </div>
      </Router>
      {/* <SiteInfo /> */}
    </div>
  );
}

export default App;
