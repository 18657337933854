import React from 'react';
import { useSelector } from 'react-redux';

function PhoneAuth() {
  const auth = useSelector((state) => {
    return state.firebase.auth;
  });

  console.log(auth);

  return <React.Fragment>{auth.phoneNumber}</React.Fragment>;
}

export default PhoneAuth;
