const purchaseDataReducer = (state = -1, action) => {
  switch (action.type) {
    case 'SETPURCHASEQUEUE':
      return action.value;
    default:
      return state;
  }
};

export default purchaseDataReducer;
