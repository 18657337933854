const defaultValue = () => {
  var myPurchases = localStorage.getItem('myPurchases') || '';
  try {
    myPurchases = JSON.parse(myPurchases);
    if (typeof myPurchases !== 'object') {
      myPurchases = [];
    }
  } catch (e) {
    myPurchases = [];
  }
  return myPurchases;
};

const myPurchasesReducer = (state = defaultValue(), action) => {
  switch (action.type) {
    case 'ADD_MYPURCHASE':
      if (!state.includes(action.value)) {
        state.push(action.value);
        localStorage.setItem('myPurchases', JSON.stringify(state));
      }
      return [ ...state ];
    case 'DEL_MYPURCHASE':
      if (state.includes(action.value)) {
        state.splice(state.indexOf(action.value), 1);
        localStorage.setItem('myPurchases', JSON.stringify(state));
      }
      return [ ...state ];
    case 'RES_MYPURCHASE':
      localStorage.removeItem('myPurchases');
      return [];
    default:
      return state;
  }
};

export default myPurchasesReducer;
