import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  LinearProgress,
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import WakeUpImage from '../images/wakeupornot.gif';

function PleaseWait(props) {
  var lastAgent = useSelector((state) => state.lastAgentId);

  return lastAgent ? <InQueue {...props} /> : <WrongAccess {...props} />;
}

export default PleaseWait;

function InQueue(props) {
  var lastAgent = useSelector((state) => state.lastAgentId);
  var queue = useSelector((state) => state.purchaseQueue);

  const [ maxQueue, setMaxQueue ] = useState(0);
  queue > maxQueue && setMaxQueue(queue);

  if (lastAgent) {
    const style = makeStyles((theme) => ({
      progressBar: {
        height: theme.spacing(3),
        'margin-top': theme.spacing(2),
        'margin-bottom': theme.spacing(1),
      },
    }))();
    return (
      <React.Fragment>
        <Dialog open={true} aria-describedby="loading-dialog-description" fullWidth={true}>
          <DialogContent id="loading-dialog-description">
            <Grid container justify="center" alignItems="center" spacing={0}>
              <Grid item>
                <img src={WakeUpImage} alt="Elsa Wakeup" />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom align="center">
                  잠시만 기다려주세요
                </Typography>
              </Grid>
            </Grid>
            {queue >= 0 &&
            maxQueue !== 0 && (
              <Grid container>
                <Grid item xs={12}>
                  <LinearProgress
                    variant="determinate"
                    value={(maxQueue - queue) / maxQueue * 100}
                    className={style.progressBar}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle2" gutterBottom>
                    남은사람: {queue}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => props.history.goBack()} color="primary">
              뒤로가기
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

function WrongAccess(props) {
  return (
    // 잘못된 접근입니다.
    <React.Fragment>
      <Dialog
        open={true}
        // onClose={() => props.history.push('/')}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'잘못된 접근입니다'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">홈으로 돌아가서 다시 시도해주세요.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => props.history.push('/')} color="primary" autoFocus>
            홈으로
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
