import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';

function Complete({ name, email }) {
  return (
    <Dialog
      open={true}
      // onClose={() => window.location.href = "/"}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{`${name} 추가되었습니다`}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          새로 만드신 항목이 비공개로 저장되었습니다. <br />
          <br />
          <pre>{email}</pre>
          <br />
          전송된 이메일을 확인하셔서 활성화 해주시기 바랍니다.<br />
          ※ 메일이 도착하지 않았을경우 스팸 메일함도 확인해주세요.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            window.location.href = '/';
          }}
          color="primary"
          autoFocus
        >
          홈으로...
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Complete;
