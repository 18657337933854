import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Grid, Paper } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useSelector } from 'react-redux';
import axios from 'axios';

import TitleBar from './TitleBar';

import uriList from '../../data/uriList';
import MenuDrawer from './MenuDrawer';

function Heading({ drawerWidth, classes }) {
  const [ lastAnnouncement, setLastAnnouncement ] = useState({
    title: '로딩중',
    link: '',
  });

  const socketId = useSelector((state) => state.socketIoId);
  const open = useSelector((state) => state.navOpen);

  useEffect(() => {
    // Announcement Hopefully Next Update.
    axios
      .get(`${uriList.API_URI}/announcement`)
      .then((doc) => {
        const { title, link } = doc.data;
        if (title && link) {
          setLastAnnouncement({
            title: title,
            link: link,
          });
        } else {
          setLastAnnouncement({
            title: '오류: 서버오류',
            link: 'https://gallog.dcinside.com/frozeninside/posting/main?gno=2273',
          });
        }
      })
      .catch(() => {
        setLastAnnouncement({
          title: '오류: 서버 연결 불가능',
          link: 'https://gallog.dcinside.com/frozeninside/posting/main?gno=2273',
        });
      });
  }, []);

  return ReactDOM.createPortal(
    <React.Fragment>
      <Grid container>
        <TitleBar drawerWidth={drawerWidth} />
        <Grid item xs={12}>
          <Paper elevation={0} className={`${classes.content} ${open && classes.contentShift}`}>
            {socketId === '' && <Alert severity="error">서버와 연결중입니다.</Alert>}
            <Alert severity="info">
              <a href={lastAnnouncement.link} target="_blank" rel="noopener noreferrer">
                {lastAnnouncement.title}
              </a>
            </Alert>
          </Paper>
        </Grid>
      </Grid>
      <MenuDrawer drawerWidth={drawerWidth} />
    </React.Fragment>,
    document.getElementById('root-heading')
  );
}

export default Heading;
