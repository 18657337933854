import React, { useEffect, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useFirebase } from 'react-redux-firebase';
import { useSelector, useDispatch } from 'react-redux';

export default function PhoneDialog() {
  const firebase = useFirebase();

  const dispatch = useDispatch();

  const open = useSelector((state) => state.phoOpen);
  const [ confirmCode, setConfirmCode ] = React.useState(null);

  const [ phoneNumber, setPhoneNumber ] = React.useState('');

  const resetFields = () => {
    setConfirmCode(null);
    setPhoneNumber('');
  };

  // const handleClickOpen = () => {
  //   dispatch({
  //     type: 'PHOOPEN',
  //   });
  // };

  const handleClose = useCallback(
    () => {
      dispatch({
        type: 'PHOCLOSE',
      });
    },
    [ dispatch ]
  );

  const onSignInSubmit = (e) => {
    // e.preventDefault();
    // setupRecaptcha();
    setConfirmCode('');
    var appVerifier = window.recaptchaVerifier;
    let phone = phoneNumber.replace(/[^0-9]+/g, '');
    phone = '+82' + phone;
    console.log(phone);
    firebase
      .auth()
      .signInWithPhoneNumber(phone, appVerifier)
      .then(function(confirmationResult) {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
      })
      .catch(function(error) {
        console.error(error);
        setConfirmCode(null);
      });

    // .finally(function() {
    //   window.recaptchaVerifier.clear();
    // });
  };

  const onSignInSubmitCB = useCallback(onSignInSubmit, [ firebase, window, phoneNumber ]);
  const onSendCode = useCallback(
    (e) => {
      var code = confirmCode;
      console.log('Sent Code: ' + code);
      if (window.confirmationResult == null) return;
      window.confirmationResult
        .confirm(code)
        .then(function(result) {
          // User signed in successfully.
          // var user = result.user;
          // console.log(user);
          resetFields();
          handleClose();
          // ...
        })
        .catch(function(error) {
          // User couldn't sign in (bad verification code?)
          // ...
          console.error(error);
          setConfirmCode(null);
        });
    },
    [ confirmCode, handleClose ]
  );

  // Setup Recaptcha (Phone Auth)
  // Setup Recaptcha
  useEffect(
    () => {
      if (window.recaptchaVerifier != null) return;
      console.log('Setup Recaptcha');
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
        size: 'invisible',
        // callback: function(response) {
        //   // reCAPTCHA solved, allow signInWithPhoneNumber.
        //   onSignInSubmitCB();
        // },
      });
      // return () => window.recaptchaVerifier.clear();
    },
    [ firebase ]
  );

  return (
    <div>
      <div id="recaptcha-container" />
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">핸드폰 인증</DialogTitle>
        <DialogContent>
          <DialogContentText>정보를 보호하기 위해 휴대폰 인증을 진행합니다.</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="phonenumber"
            label="휴대폰번호"
            type="text"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            fullWidth
          />
          <Button
            onClick={(e) => {
              onSignInSubmitCB(e);
            }}
            disabled={confirmCode != null}
            color="secondary"
            variant="outlined"
          >
            인증번호 전송
          </Button>
          <TextField
            margin="dense"
            id="phoneauthnumber"
            label="인증번호"
            type="text"
            disabled={confirmCode == null}
            value={confirmCode != null ? confirmCode : '인증번호를 전송해주세요'}
            onChange={(e) => setConfirmCode(e.target.value)}
            fullWidth
          />
          <Button
            onClick={(e) => {
              onSendCode(e);
            }}
            disabled={confirmCode == null || confirmCode === ''}
            color="secondary"
            variant="outlined"
          >
            인증번호 확인
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            취소
          </Button>
          {/* <Button onClick={handleClose} color="primary">
            확인
          </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}
