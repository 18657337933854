import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFirebase } from 'react-redux-firebase';
import {
  TextField,
  Button,
  Grid,
  Paper,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';

import uriList from '../data/uriList';
import ConnectionWarning from './utils/ConnectionWarning';

function LookUpForm({ match, location, ...props }) {
  const firebase = useFirebase();
  const dispatch = useDispatch();
  const [ name, setName ] = useState(localStorage.getItem('savedname') || '');
  // const [ number, setNumber ] = useState(localStorage.getItem('savednumber') || '');
  const [ validError, setValidError ] = useState({});
  const [ lastAgent, setLastAgent ] = useState('');
  const [ confirm, setConfirm ] = useState(false);
  const [ warnOpen, setWarnOpen ] = useState(false);
  const [ notFound, setNotFound ] = useState(false);

  const engName = match.params.engName;
  const socketId = useSelector((state) => state.socketIoId);
  const thisPurchase = useSelector((state) => state.thisPurchase);
  const auth = useSelector((state) => {
    return state.firebase.auth;
  });

  useEffect(
    () => {
      dispatch({
        type: 'SETPURCHASEDATA',
        value: {},
      });

      dispatch({
        type: 'SETPURCHASEQUEUE',
        value: -1,
      });
    },
    [ dispatch ]
  );

  useEffect(
    () => {
      dispatch({
        type: 'setLastAgentId',
        value: lastAgent,
      });
    },
    [ lastAgent, dispatch ]
  );

  useEffect(
    () => {
      localStorage.setItem('savedname', name);
      // localStorage.setItem('savednumber', number);

      // dispatch({
      //   type: 'SETUSERINFO',
      //   value: {
      //     name: name,
      //     phoneNo: number,
      //   },
      // });
    },
    [ name /*, number*/ ]
  );

  /*! 
  FUNCTIONS
  -----------------
  */
  const validateName = (e) => {
    dispatch({
      type: 'RES_MYPURCHASE',
    });
    var name = e.target.value.trim().replace(/\d/g, '');
    setName(name);

    if (name.length === 0) {
      setValidError({
        ...validError,
        name: {
          message: '성함을 입력해주세요',
        },
      });
    } else if (false) {
      //name.length > 3
      setValidError({
        ...validError,
        name: {
          message: '성함은 3자 이하여야합니다.',
        },
      });
    } else {
      if (validError['name']) {
        delete validError['name'];
      }
    }
  };

  // const validateNumber = (e) => {
  //   var number = e.target.value.trim().replace(/[^0-9]/g, '');
  //   setNumber(number);

  //   if (number.length !== 4) {
  //     setValidError({
  //       ...validError,
  //       number: {
  //         message: '휴대폰 번호 뒷 4자리만 입력해주세요',
  //       },
  //     });
  //   } else {
  //     if (validError['number']) {
  //       delete validError['number'];
  //     }
  //   }
  // };

  const sendForm = (e) => {
    e && e.preventDefault();
    if (!firebase.auth().currentUser) return;
    setConfirm(true);

    firebase
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(function(idToken) {
        // console.log(idToken);
        axios
          .post(`${uriList.API_URI}/purchase`, {
            engName: engName,
            buyerName: name,
            // lastPhoneNo: number,
            socketioId: socketId,
            idToken: idToken,
          })
          .then(({ data }) => {
            // console.log(data);
            setLastAgent(data.workerId);
            props.history.push('/data');
          })
          .catch((e) => {
            // alert('서버에 연결 할 수 없습니다.');
            setConfirm(false);

            setWarnOpen(true);
          });
      })
      .catch(function(error) {
        setConfirm(false);

        setWarnOpen(true);
      });
  };

  const sendFormCallback = useCallback(sendForm, [ engName, name, /* number, */ socketId ]);

  useEffect(
    () => {
      if (location.query && location.query.lookup) {
        // console.log({
        //   engName: engName,
        //   buyerName: name,
        //   lastPhoneNo: number,
        //   socketioId: socketId,
        // });
        sendFormCallback();
      }
    },
    [ location.query, sendFormCallback ]
  );

  const pathEngName = window.location.pathname.split('/')[1];
  useEffect(
    () => {
      // console.log(thisPurchase);
      if (!Object.keys(thisPurchase).length) {
        axios.get(`${uriList.API_URI}/purchase?engName=${pathEngName}`).then(({ data }) => {
          // console.log({ data: data, engName: pathEngName });
          if (data.length === 1) {
            dispatch({
              type: 'SETTHISPURCHASE',
              value: data[0],
            });
          } else {
            setNotFound(true);
            dispatch({
              type: 'SETTHISPURCHASE',
              value: { engName: pathEngName, name: '알 수 없 음' },
            });
          }
        });
      }
    },
    [ pathEngName, thisPurchase, dispatch ]
  );

  // if (myPurchases.includes(engName)) {
  //   sendForm();
  // }

  const style = makeStyles((theme) => ({
    root: {
      'padding-top': theme.spacing(3),
      'padding-bottom': theme.spacing(3),
    },
    form: {
      // display: 'flex',
      '& .MuiTextField-root': {
        width: 200,
      },
    },

    button: {
      'font-size': '1.222em',
    },
  }))();

  return (
    <React.Fragment>
      {warnOpen && <ConnectionWarning closecb={() => setWarnOpen(false)} />}
      <Dialog
        open={notFound}
        onClose={() => window.location.replace('/')}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'잘못된 링크입니다'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            입력하신 링크가 잘못되었습니다.<br />
            링크를 확인하시고 다시 시도해주세요.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => window.location.replace('/')} color="primary" autoFocus>
            홈으로
          </Button>
        </DialogActions>
      </Dialog>
      <Paper elevation={0} className={style.root} square={false}>
        <form onSubmit={sendForm} className={style.form}>
          <Grid container justify="center" alignItems="center" spacing={2}>
            <Grid container item justify="center" alignItems="center" spacing={1}>
              <Grid item>
                <Typography variant="h3" gutterBottom>
                  {thisPurchase.name}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h5" gutterBottom>
                  ({thisPurchase.engName})
                </Typography>
              </Grid>
            </Grid>
            <Grid container item justify="center" spacing={1}>
              <Grid item>
                <TextField
                  className={style.textfield}
                  label="성함"
                  value={name}
                  onChange={validateName}
                  variant="outlined"
                  // margin="normal"
                />
              </Grid>
              <Grid item>
                <TextField
                  className={style.textfield}
                  label="전화번호 뒷 4자리"
                  value={auth.phoneNumber ? auth.phoneNumber.substr(auth.phoneNumber.length - 4) : '휴대폰 인증해주세요'}
                  disabled={true}
                  // value={number}
                  // onChange={validateNumber}
                  error={validError.number !== undefined}
                  variant="outlined"
                  helperText={(validError.number && validError.number.message) || ''}
                />
              </Grid>
            </Grid>
            <Button
              className={style.button}
              variant="contained"
              color="primary"
              disabled={Object.keys(validError).length !== 0 || !name || !auth.phoneNumber || confirm}
              type="submit"
              // disableElevation
            >
              조회
            </Button>
          </Grid>
        </form>
      </Paper>
    </React.Fragment>
  );
}

export default LookUpForm;
