import { combineReducers } from 'redux';

import socketIoIdReducer from './isSocketIo';
import lastAgentReducer from './lastAgent';
import purchaseDataReducer from './purchaseData';
import purchaseQueueReducer from './purchaseQueue';
import navOpenReducer from './navOpen';
import purchaseReducer from './allPurchases';
import thisPurchaseReducer from './thisPurchase';
import myPurchasesReducer from './myPurchases';
import starredNotionReducer from './starredNotion';
import phoOpenReducer from './phoOpen';

const allReducers = {
  socketIoId: socketIoIdReducer,
  lastAgentId: lastAgentReducer,
  purchaseData: purchaseDataReducer,
  purchaseQueue: purchaseQueueReducer,
  allPurchases: purchaseReducer,
  navOpen: navOpenReducer,
  phoOpen: phoOpenReducer,
  thisPurchase: thisPurchaseReducer,
  myPurchases: myPurchasesReducer,
  starredNotion: starredNotionReducer,
};

export default allReducers;
