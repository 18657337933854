import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';

function ConnectionWarning({ closecb, forceRefresh = false }) {
  forceRefresh = process.env.NODE_ENV === 'development' ? false : forceRefresh;
  return (
    <React.Fragment>
      <Dialog
        open={true}
        onClose={() => window.location.reload()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'서버에 연결 할 수 없습니다'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            인터넷 연결이 끊어 졌거나 서버가 업데이트 중일 수 있습니다.<br />
            새로고침을 하신후에 다시 시도해주세요.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {!forceRefresh && (
            <Button onClick={closecb} color="secondary">
              닫기
            </Button>
          )}
          <Button onClick={() => window.location.reload()} color="primary" autoFocus>
            새로고침
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default ConnectionWarning;
