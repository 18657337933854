import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Drawer, Divider, IconButton, Button } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import { ChevronLeft as ChevronLeftIcon, Add as AddIcon } from '@material-ui/icons';
import MenuMyPurchase from './MenuMyPurchase';
import MenuAllPurchase from './MenuAllPurchase';

// TODO: Test Phone Dialog
import PhoneDialog from '../Auth/PhoneDialog';

function MenuDrawer({ drawerWidth }) {
  const dispatch = useDispatch();
  const open = useSelector((state) => state.navOpen);

  const classes = makeStyles((theme) => ({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      '& .MuiListSubheader-sticky': {
        backgroundColor: theme.palette.background.paper,
      },
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
      justifyContent: 'space-between',
    },
  }))();

  return (
    <React.Fragment>
    <PhoneDialog />
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <Button
            // variant="contained"
            // color="default"
            onClick={() => (window.location.href = '/add')}
            startIcon={<AddIcon />}
          >
            추가하기
          </Button>
          <IconButton onClick={() => dispatch({ type: 'NAVCLOSE' })}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <MenuMyPurchase />
        <MenuAllPurchase />
      </Drawer>
    </React.Fragment>
  );
}

export default MenuDrawer;
