import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { List, ListItem, ListItemText, ListSubheader } from '@material-ui/core';

function MenuAllPurchase() {
  const dispatch = useDispatch();
  const allPurchases = useSelector((state) => state.allPurchases);
  var myPurchases = useSelector((state) => state.myPurchases);
  return (
    <List
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          모든 공구 목록
        </ListSubheader>
      }
    >
      {allPurchases.map(
        (purchase) =>
          !myPurchases.includes(purchase.engName) ? (
            <Link
              key={purchase._id}
              onClick={() => dispatch({ type: 'NAVCLOSE' })}
              to={`/${purchase.engName}`}
              style={{ color: 'inherit', textDecoration: 'none' }}
            >
              <ListItem button selected={window.location.pathname.split('/')[1] === purchase.engName}>
                <ListItemText primary={purchase.name} />
              </ListItem>
            </Link>
          ) : null
      )}
    </List>
  );
}

export default MenuAllPurchase;
