export const fbConfig = {
  apiKey: 'AIzaSyCpIFVBIhptnUsV4oOQZeHqbAWu9TH0hOw',
  authDomain: 'lookupauth.firebaseapp.com',
  databaseURL: 'https://lookupauth.firebaseio.com',
  projectId: 'lookupauth',
  storageBucket: 'lookupauth.appspot.com',
  messagingSenderId: '736214345380',
  appId: '1:736214345380:web:3075b2936177b03a7c2831',
};

export default fbConfig;
