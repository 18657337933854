const phoOpenReducer = (state = false, action) => {
  switch (action.type) {
    case 'PHOOPEN':
      return true;
    case 'PHOCLOSE':
      return false;
    case 'PHOSET':
      return action.value;
    default:
      return state;
  }
};

export default phoOpenReducer;
